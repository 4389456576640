import { addDays } from "date-fns";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { Moment } from "moment";
import { getMinCheckinDays, useLayoutStore } from "@store/layoutStore";
import { shallow } from "zustand/shallow";

type WeekContextType = {
  dates: WeekDateType;
  searchText: string;
  allDates: boolean;
  setDates: (date1: Date | Moment, date2: Date | Moment) => void;
  setDate: (key: "checkIn" | "checkOut", date: Date | Moment) => void;
  setSearchText: (val: string) => void;
  setAllDates: (val: boolean) => void;
};

const WeekContextDefaultValues: WeekContextType = {
  dates: {
    checkIn: new Date(),
    checkOut: addDays(new Date(), 1),
  },
  searchText: "",
  allDates: true,
  setDate: () => {},
  setDates: () => {},
  setSearchText: () => {},
  setAllDates: () => {},
};

const WeekContext = createContext<WeekContextType>(WeekContextDefaultValues);

export function useWeek() {
  return useContext(WeekContext);
}

type Props = {
  children: ReactNode;
};

export function WeekProvider({ children }: Props) {
  const { isLoadedExternalConfig, isLoadedCheckLogin } = useLayoutStore(
    (state) => ({
      isLoadedExternalConfig: state.isLoadedExternalConfig,
      isLoadedCheckLogin: state.isLoadedCheckLogin,
    }),
    shallow
  );
  useEffect(() => {
    if (isLoadedExternalConfig && isLoadedCheckLogin) {
      setDates(getMinCheckinDays(), addDays(getMinCheckinDays(), 1));
    }
  }, [isLoadedExternalConfig, isLoadedCheckLogin]);
  const [dates, setDateInfo] = useState<WeekDateType>(
    WeekContextDefaultValues.dates
  );
  const [searchText, setSearchTextState] = useState<string>(
    WeekContextDefaultValues.searchText
  );
  const [allDates, setAllDatesState] = useState(
    WeekContextDefaultValues.allDates
  );

  const setDate = (key: "checkIn" | "checkOut", date: Date | Moment) => {
    setDateInfo({
      ...dates,
      [key]: date,
    });
  };
  const setDates = (date1: Date | Moment, date2: Date | Moment) => {
    setDateInfo({
      checkIn: date1,
      checkOut: date2,
    });
  };

  const setSearchText = (val: string) => {
    setSearchTextState(val);
  };
  const setAllDates = (val: boolean) => {
    setAllDatesState(val);
  };

  const value = {
    dates,
    searchText,
    allDates,
    setDate,
    setDates,
    setAllDates,
    setSearchText,
  };

  WeekContext.displayName = "WeekContext";
  return <WeekContext.Provider value={value}>{children}</WeekContext.Provider>;
}
