import { getExternalConfig } from "@lib/api/layout";
import { create } from "zustand";
import weekDayNumber from "@data/calendarStartWeekDay/weekDayNumber.json";
import { getTodayDate } from "@helpers/helperAds";
import { uuidv4 } from "@lib/helpers";
import { setIsEnableLoaderGPT } from "@helpers/loader";
import { getPlansV2 } from "@lib/api/layout";
import { userProfileStore } from "./userProfileStore";
import moment from "moment";
import { addDays, isBefore } from "date-fns";
interface LayoutStoreState {
  currencyRate?: number | null;
  footer?: FooterApiType | null;
  loaded: boolean;
  config: ConfigApiType | null;
  isLoadedCheckLogin: boolean;
  nationality: string;
  externalConfig?: DomainsConfigType | null;
  countryList: CountryListType[];
  productMenuSelected: string;
  productSubmenuSelected: string;
  isLoadedExternalConfig: boolean;
  weekStartsOn: 0 | 1 | 2 | 3 | 4 | 5 | 6 | undefined;
  adsCheckinDate: string;
  refreshConfig: string;
  showUpgradeUserModal: boolean;
  displayPrice: string;
  can_user_book: boolean;
  showUserDemoModal: boolean;
  showSubmenu: boolean;
  isOpenCashModal: boolean;
  openPlansModal: boolean;
  plansv2Data?: DataPlansV2Type | null;
  productCanBook: boolean;
  showHeader: boolean;
  showModalMinCheckin: boolean;
  disabledDiv: boolean;
  setDisableDiv: (value: boolean) => void;
  setProductCanBook: (flag: boolean) => void;
  setPlansv2Data: (value: DataPlansV2Type | null) => void;
  setOpenPlansModal: (value: boolean) => void;
  setIsOpenCashModal: (value: boolean) => void;
  setShowSubmenu: (value: boolean) => void;
  setDisplayPrice: (val: string) => void;
  setShowUpgradeUserModal: (value: boolean) => void;
  setAdsCheckinDate: (date: string) => void;
  setWeekStartsOn: (val: 0 | 1 | 2 | 3 | 4 | 5 | 6 | undefined) => void;
  setProductMenuSelected: (value: string) => void;
  setProductSubmenuSelected: (value: string) => void;
  setCurrencyRate: (value: number | null) => void;
  setFooter: (value: FooterApiType) => void;
  setLoaded: (value: boolean) => void;
  setConfig: (value: ConfigApiType | null) => void;
  setIsLoadedCheckLogin: (value: boolean) => void;
  setNationality: (value: string) => void;
  setExternalConfig: (value: DomainsConfigType | null) => void;
  fetcherExternalConfig: (orgId?: number) => void;
  setCountryList: (value: CountryListType[]) => void;
  fetcherUpdateWeekStartOn: (value: string) => void;
  setRefreshConfig: () => void;
  setCan_user_book: (value: boolean) => void;
  setShowUserDemoModal: (value: boolean) => void;
  setShowHeader: (value: boolean) => void;
  setShowModalMinCheckin: (value: boolean) => void;
}

export const useLayoutStore = create<LayoutStoreState>((set) => ({
  currencyRate: null,
  footer: null,
  loaded: false,
  config: null,
  isLoadedCheckLogin: false,
  nationality: "",
  externalConfig: null,
  countryList: [],
  productMenuSelected: "",
  productSubmenuSelected: "",
  isLoadedExternalConfig: false,
  weekStartsOn: 0,
  adsCheckinDate: getTodayDate(),
  refreshConfig: "",
  showUpgradeUserModal: false,
  displayPrice: "PNT",
  can_user_book: false,
  showUserDemoModal: false,
  showSubmenu: false,
  isOpenCashModal: false,
  openPlansModal: false,
  plansv2Data: null,
  productCanBook: false,
  showHeader: false,
  showModalMinCheckin: false,
  disabledDiv: false,
  setDisableDiv: (value: boolean) => set({ disabledDiv: value }),
  setProductCanBook: (flag: boolean) => set({ productCanBook: flag }),
  setPlansv2Data: (value: DataPlansV2Type | null) =>
    set({ plansv2Data: value }),
  setOpenPlansModal: (flag: boolean) => set({ openPlansModal: flag }),
  setIsOpenCashModal: (flag: boolean) => set({ isOpenCashModal: flag }),
  setShowSubmenu: (flag: boolean) => set({ showSubmenu: flag }),
  setDisplayPrice: (value: string) => {
    set({ displayPrice: value });
  },
  setShowUpgradeUserModal: (flag: boolean) =>
    set({ showUpgradeUserModal: flag }),
  setAdsCheckinDate: (value: string) => set({ adsCheckinDate: value }),
  setWeekStartsOn: (value: 0 | 1 | 2 | 3 | 4 | 5 | 6 | undefined) =>
    set({ weekStartsOn: value }),
  setProductMenuSelected: (value: string) =>
    set({ productMenuSelected: value }),
  setProductSubmenuSelected: (value: string) =>
    set({ productSubmenuSelected: value }),
  setCurrencyRate: (value: number | null) => set({ currencyRate: value }),
  setFooter: (value: FooterApiType) => set({ footer: value }),
  setLoaded: (value: boolean) => set({ loaded: value }),
  setConfig: (value: ConfigApiType | null) => set({ config: value }),
  setIsLoadedCheckLogin: (value: boolean) => set({ isLoadedCheckLogin: value }),
  setNationality: (value: string) => set({ nationality: value }),
  setExternalConfig: (value: DomainsConfigType | null) => {
    const isEnabledLoaderGpt = !!value?.showCityGpt;
    setIsEnableLoaderGPT(isEnabledLoaderGpt);
    set({ externalConfig: value, isLoadedExternalConfig: true });
  },
  fetcherExternalConfig: async (orgId?: number) => {
    if (Number(orgId) > 0) {
      getExternalConfig(orgId).then((data) => {
        const isEnabledLoaderGpt = !!data?.showCityGpt;
        setIsEnableLoaderGPT(isEnabledLoaderGpt);
        set({ externalConfig: data, isLoadedExternalConfig: true });
      });
    }
  },
  fetcherUpdateWeekStartOn: (value: string) => {
    let dayNameShort = value ? value?.split("-")[0] : "Sun";

    weekDayNumber?.weekDay?.map((obj: any) => {
      if (obj?.day == dayNameShort) {
        set({ weekStartsOn: obj?.weekDayNumber || 0 });
      }
    });
  },
  setCountryList: (value: CountryListType[]) => set({ countryList: value }),
  setRefreshConfig: () => set({ config: null, refreshConfig: uuidv4() }),
  setCan_user_book: (value: boolean) => set({ can_user_book: value }),
  setShowUserDemoModal: (value: boolean) => set({ showUserDemoModal: value }),
  setShowHeader: (value: boolean) => set({ showHeader: value }),
  setShowModalMinCheckin: (value: boolean) =>
    set({ showModalMinCheckin: value }),
}));

export const isCanUserBookModifiedSystem = () => {
  const { userNewInfoProfile } = userProfileStore.getState();
  let basicProfile = userNewInfoProfile.basicprofile.find((f) => f);

  if (basicProfile) {
    return basicProfile.can_user_book_modified == "system";
  }
  return true;
};

export const isAllowedBookByPlan = async (
  userId: string,
  canBookReplace = false
) => {
  const { plansv2Data, productCanBook } = useLayoutStore.getState();

  console.log("MOdal Upgradfe allowed", plansv2Data, productCanBook);
  if (!plansv2Data) {
    const planInfo = await getPlansV2(userId);
    if (planInfo) {
      const plans = planInfo.data;
      useLayoutStore.setState({
        plansv2Data: plans,
        openPlansModal: plans.displayModal,
      });

      if (plans.displayModal && plans.canBook) {
        if (!productCanBook) {
          useLayoutStore.setState({
            productCanBook: true,
            openPlansModal: plans.displayModal,
          });
          return plans.displayModal;
        } else if (canBookReplace) {
          useLayoutStore.setState({
            productCanBook: true,
            openPlansModal: plans.displayModal,
          });
          return true;
        } else {
          return false;
        }
      }
      return plans.displayModal;
    }
  }

  if (plansv2Data) {
    if (plansv2Data.displayModal && plansv2Data.canBook) {
      if (!productCanBook) {
        useLayoutStore.setState({
          productCanBook: true,
          openPlansModal: plansv2Data.displayModal,
        });
        return plansv2Data.displayModal;
      } else if (canBookReplace) {
        console.log("MOdal Upgradfe  canBookReplace", canBookReplace);
        useLayoutStore.setState({
          productCanBook: true,
          openPlansModal: plansv2Data.displayModal,
        });
        return true;
      } else {
        return false;
      }
    }
    useLayoutStore.setState({ openPlansModal: plansv2Data.displayModal });
    return plansv2Data.displayModal;
  }

  return false;
};

export const checkIfCheckingDateIsCorrect = (checkinDate: Date | string) => {
  // validate with moment
  const checkinDateCheck = moment(checkinDate).isValid()
    ? moment(checkinDate).toDate()
    : new Date(checkinDate);
  const { config } = useLayoutStore.getState();
  const minCheckinDays = config?.orgBySite?.minCheckinDays || 1;
  const today = new Date();
  const diffTime = Math.abs(checkinDateCheck.getTime() - today.getTime());
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  const isCorrect = diffDays >= minCheckinDays;
  if (!isCorrect) {
    useLayoutStore.setState({ showModalMinCheckin: true });
  }

  return isCorrect;
};

export const getMinCheckinDays = () => {
  const { config } = useLayoutStore.getState();
  const minCheckinDays = config?.orgBySite?.minCheckinDays || 1;

  return addDays(new Date(), minCheckinDays);
};

export const getDateisBeforemincheckdays = (checkinDate: Date | string) => {
  const checkinDateCheck = moment(checkinDate).isValid()
    ? moment(checkinDate).toDate()
    : new Date(checkinDate);
  let isCorrect = false;
  if (isBefore(checkinDateCheck, getMinCheckinDays())) {
    isCorrect = true;
  }

  return isCorrect;
};
