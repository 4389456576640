import { DaysDropDown } from "@data/flight/search";
import { INIT_CHECKIN, INIT_CHECKOUT } from "@data/consts";
// import { addDays } from "date-fns";
import { Moment } from "moment";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { shallow } from "zustand/shallow";
import { useLayoutStore, getMinCheckinDays } from "@store/layoutStore";
import { addDays } from "date-fns";

type FlightContextType = {
  flightType: OptionsType;
  flightClass: OptionsType;
  bags: OptionsType;
  dates: FlightDateType;
  searchTextFrom: string;
  searchTextTo: string;
  pax: FlightPaxType;
  suggestedFrom: FlightAutoSuggestType | null;
  suggestedTo: FlightAutoSuggestType | null;
  setDates: (date1: Date | Moment, date2: Date | Moment) => void;
  setDate: (key: string, date: Date | Moment) => void;
  setSearchFromText: (text: string) => void;
  setSearchToText: (text: string) => void;
  setPax: (paxConfig: FlightPaxType) => void;
  setFlightType: (text: OptionsType) => void;
  setFlightClass: (text: OptionsType) => void;
  setBags: (text: OptionsType) => void;
  setSuggestedFrom: (d: FlightAutoSuggestType | null) => void;
  setSuggestedTo: (d: FlightAutoSuggestType | null) => void;
  airLinesSelectAll: boolean;
  airLinesClearAll: boolean;
  handleSetAirLinesSelectAll: (state: boolean) => void;
  handleSetAirLinesClearAll: (state: boolean) => void;
  airportDepartureReturnSame: boolean;
  handleAirportDepartureReturnSame: (state: boolean) => void;
  autoValidateTraveler: boolean;
  pasaAutoValidateTraveler: boolean;
  hanldeSetAutoValidateTraveler: (state: boolean) => void;
  hanldePasaSetAutoValidateTraveler: (state: boolean) => void;
  previewPricesInfo: pricesFlightSaved[];
  setPreviewPricesInfo: (obj: pricesFlightSaved[]) => void;
  routeSelectedFlight: pricesFlightSaved[];
  setRouteSelectedFlight: (obj: pricesFlightSaved[]) => void;
  priceSelectedFlight: selectedRouteFlight | null;
  setPriceSelectedFlight: (obj: selectedRouteFlight | null) => void;
  startEndTripDate: startEndTripDate;
  setStartEndTripDate: (obj: startEndTripDate) => void;
  friendTrips: FriendsSelectedType[];
  setFriendsTrip: (obj: FriendsSelectedType[]) => void;
  departureMatrixDate: matrixDateType | null;
  departureSetMatrixDate: (obj: matrixDateType) => void;
  returnMatrixDate: matrixDateType | null;
  returnSetMatrixDate: (obj: matrixDateType) => void;
  executeSearchFlightInventory: boolean;
  setExecuteSearchFlightInventory: (state: boolean) => void;
  couponCode: ApplyCouponCodeType | null;
  setCouponCode: (value: ApplyCouponCodeType | null) => void;
  flightCashAmount: PaymentMethodCashAmountCars | null;
  setFlightCashAmount: (data: PaymentMethodCashAmountCars | null) => void;
};

const FlightContextDefaultValues: FlightContextType = {
  flightType: { id: 2, value: "Round Trip", label: "Round Trip" },
  flightClass: { id: 1, name: "cabin", value: "Economy", label: "Economy" },
  bags: { id: 1, value: "0 Bags", label: "0 Bags" },
  dates: {
    from: INIT_CHECKIN,
    to: INIT_CHECKOUT,
  },
  searchTextFrom: "",
  searchTextTo: "",
  pax: { adult: 1, child: 0, infant: 0 },
  suggestedFrom: null,
  suggestedTo: null,
  setDate: () => {},
  setDates: () => {},
  setSearchFromText: () => {},
  setSearchToText: () => {},
  setPax: () => {},
  setFlightType: () => {},
  setFlightClass: () => {},
  setBags: () => {},
  setSuggestedFrom: () => {},
  setSuggestedTo: () => {},
  airLinesSelectAll: true,
  airLinesClearAll: false,
  handleSetAirLinesSelectAll: () => {},
  handleSetAirLinesClearAll: () => {},
  airportDepartureReturnSame: true,
  handleAirportDepartureReturnSame: () => {},
  autoValidateTraveler: false,
  pasaAutoValidateTraveler: true,
  hanldeSetAutoValidateTraveler: () => {},
  hanldePasaSetAutoValidateTraveler: () => {},
  previewPricesInfo: [],
  setPreviewPricesInfo: () => {},
  routeSelectedFlight: [],
  setRouteSelectedFlight: () => {},
  priceSelectedFlight: null,
  setPriceSelectedFlight: () => {},
  startEndTripDate: {
    startTripDate: "",
    endTripDate: "",
  },
  setStartEndTripDate: () => {},
  friendTrips: [],
  setFriendsTrip: () => {},
  departureMatrixDate: DaysDropDown[0],
  departureSetMatrixDate: () => {},
  returnMatrixDate: DaysDropDown[0],
  returnSetMatrixDate: () => {},
  executeSearchFlightInventory: false,
  setExecuteSearchFlightInventory: () => {},
  couponCode: null,
  setCouponCode: () => {},
  flightCashAmount: null,
  setFlightCashAmount: () => {},
};

const FlightContext = createContext<FlightContextType>(
  FlightContextDefaultValues
);

export function useFlight() {
  return useContext(FlightContext);
}

type Props = {
  children: ReactNode;
};

export function FlightProvider({ children }: Props) {
  const { isLoadedExternalConfig, isLoadedCheckLogin } = useLayoutStore(
    (state) => ({
      isLoadedExternalConfig: state.isLoadedExternalConfig,
      isLoadedCheckLogin: state.isLoadedCheckLogin,
    }),
    shallow
  );
  useEffect(() => {
    if (isLoadedExternalConfig && isLoadedCheckLogin) {
      setDates(getMinCheckinDays(), addDays(getMinCheckinDays(), 1));
    }
  }, [isLoadedExternalConfig, isLoadedCheckLogin]);

  const [dates, setDateInfo] = useState<FlightDateType>(
    FlightContextDefaultValues.dates
  );
  const [searchTextFrom, setFromTextInfo] = useState<string>(
    FlightContextDefaultValues.searchTextFrom
  );
  const [searchTextTo, setToTextInfo] = useState<string>(
    FlightContextDefaultValues.searchTextTo
  );
  const [pax, setPaxConfigInfo] = useState<FlightPaxType>(
    FlightContextDefaultValues.pax
  );
  const [flightType, setFlightTypeInfo] = useState<OptionsType>(
    FlightContextDefaultValues.flightType
  );
  const [flightClass, setFlightClassInfo] = useState<OptionsType>(
    FlightContextDefaultValues.flightClass
  );
  const [bags, setBagsInfo] = useState<OptionsType>(
    FlightContextDefaultValues.bags
  );
  const [suggestedFrom, setSuggestedFromInfo] =
    useState<FlightAutoSuggestType | null>(
      FlightContextDefaultValues.suggestedFrom
    );
  const [suggestedTo, setSuggestedToInfo] =
    useState<FlightAutoSuggestType | null>(
      FlightContextDefaultValues.suggestedTo
    );

  const [airLinesSelectAll, setAirLinesSelectAll] = useState<boolean>(
    FlightContextDefaultValues.airLinesSelectAll
  );
  const [airLinesClearAll, setAirLinesClearAll] = useState<boolean>(
    FlightContextDefaultValues.airLinesClearAll
  );

  const [airportDepartureReturnSame, setAirportDepartureReturnSame] =
    useState<boolean>(FlightContextDefaultValues.airportDepartureReturnSame);

  const [autoValidateTraveler, setAutoValidateTraveler] = useState(
    FlightContextDefaultValues.autoValidateTraveler
  );
  const [pasaAutoValidateTraveler, pasaSetAutoValidateTraveler] = useState(
    FlightContextDefaultValues.pasaAutoValidateTraveler
  );

  /* START MAP_FLIGHT */
  const [previewPricesInfo, setPreviewPricesInfo] = useState(
    FlightContextDefaultValues.previewPricesInfo
  );
  const [routeSelectedFlight, setRouteSelectedFlight] = useState(
    FlightContextDefaultValues.routeSelectedFlight
  );
  const [priceSelectedFlight, setPriceSelectedFlight] = useState(
    FlightContextDefaultValues.priceSelectedFlight
  );

  /* END MAP_FLIGHT */
  const [startEndTripDate, setStartEndTripDate] = useState(
    FlightContextDefaultValues.startEndTripDate
  );
  const [friendTrips, setFriendsTrip] = useState(
    FlightContextDefaultValues.friendTrips
  );

  const [couponCode, setCouponCode] = useState<ApplyCouponCodeType | null>(
    FlightContextDefaultValues.couponCode
  );
  const [flightCashAmount, setFlightCashAmount] =
    useState<PaymentMethodCashAmountCars | null>(
      FlightContextDefaultValues.flightCashAmount
    );

  const hanldeSetAutoValidateTraveler = (state: boolean) => {
    setAutoValidateTraveler(state);
  };

  const hanldePasaSetAutoValidateTraveler = (state: boolean) => {
    pasaSetAutoValidateTraveler(state);
  };

  const setDate = (key: string, date: Date | Moment) => {
    setDateInfo({
      ...dates,
      [key === "from" ? "from" : "to"]: date,
    });
  };

  const setDates = (date1: Date | Moment, date2: Date | Moment) => {
    setDateInfo({
      from: date1,
      to: date2,
    });
  };

  const setSearchFromText = (text: string) => {
    setFromTextInfo(text);
  };

  const setSearchToText = (text: string) => {
    setToTextInfo(text);
  };

  const setPax = (paxConfig: FlightPaxType) => {
    setPaxConfigInfo(paxConfig);
  };

  const setFlightType = (text: OptionsType) => {
    setFlightTypeInfo(text);
  };

  const setFlightClass = (text: OptionsType) => {
    setFlightClassInfo(text);
  };

  const setBags = (text: OptionsType) => {
    setBagsInfo(text);
  };

  const setSuggestedFrom = (d: FlightAutoSuggestType | null) => {
    setSuggestedFromInfo(d);
  };

  const setSuggestedTo = (d: FlightAutoSuggestType | null) => {
    setSuggestedToInfo(d);
  };

  const handleSetAirLinesSelectAll = (state: boolean) => {
    setAirLinesSelectAll(state);
  };

  const handleSetAirLinesClearAll = (state: boolean) => {
    setAirLinesClearAll(state);
  };

  const handleAirportDepartureReturnSame = (state: boolean) => {
    setAirportDepartureReturnSame(state);
  };

  /* START FLIGHT DAYS MATRIX FOR FLEXIBLE DATES */
  const [departureMatrixDate, departureSetMatrixDate] = useState(
    FlightContextDefaultValues.departureMatrixDate
  );
  const [returnMatrixDate, returnSetMatrixDate] = useState(
    FlightContextDefaultValues.returnMatrixDate
  );
  const [executeSearchFlightInventory, setExecuteSearchFlightInventory] =
    useState(FlightContextDefaultValues.executeSearchFlightInventory);
  /* END FLIGHT DAYS MATRIX FOR FLEXIBLE DATES */

  const value = {
    dates,
    searchTextFrom,
    searchTextTo,
    pax,
    flightType,
    flightClass,
    bags,
    suggestedFrom,
    suggestedTo,
    setDate,
    setDates,
    setSearchFromText,
    setSearchToText,
    setPax,
    setFlightType,
    setFlightClass,
    setBags,
    setSuggestedFrom,
    setSuggestedTo,
    airLinesSelectAll,
    airLinesClearAll,
    handleSetAirLinesSelectAll,
    handleSetAirLinesClearAll,
    airportDepartureReturnSame,
    handleAirportDepartureReturnSame,
    autoValidateTraveler,
    pasaAutoValidateTraveler,
    hanldeSetAutoValidateTraveler,
    hanldePasaSetAutoValidateTraveler,
    previewPricesInfo,
    setPreviewPricesInfo,
    routeSelectedFlight,
    setRouteSelectedFlight,
    priceSelectedFlight,
    setPriceSelectedFlight,
    startEndTripDate,
    setStartEndTripDate,
    friendTrips,
    setFriendsTrip,
    departureMatrixDate,
    departureSetMatrixDate,
    returnMatrixDate,
    returnSetMatrixDate,
    executeSearchFlightInventory,
    setExecuteSearchFlightInventory,
    couponCode,
    setCouponCode,
    flightCashAmount,
    setFlightCashAmount,
  };

  FlightContext.displayName = "FlightContext";
  return (
    <FlightContext.Provider value={value}>{children}</FlightContext.Provider>
  );
}
