import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
// import { addDays } from "date-fns";
import { Moment } from "moment";
import { useRouter } from "next/router";
import {
  getHourFormatHoppa,
  getParamsFromQuery,
  getRequestSearchFromStates,
} from "@helpers/helperTransfers";
import { getDateToFormat } from "@helpers/helper";
import { INIT_CHECKIN, INIT_CHECKOUT } from "@data/consts";
import { shallow } from "zustand/shallow";
import { useLayoutStore, getMinCheckinDays } from "@store/layoutStore";
import { addDays } from "date-fns";

type TransfersContextType = {
  dates: TransfersDateType;
  pickup: string;
  pickupTime: string;
  pickupTime2: string;
  pickupLat: number;
  pickupLng: number;
  destination: string;
  destinationLat: number;
  destinationLng: number;
  isReturn: boolean;
  adults: number;
  childrens: number;
  infants: number;
  hideCalendar2: boolean;
  onLoad: boolean;
  searchReq: TransferSearchReqType | null;
  extrasAvailable: ExtrasAvailableType[];
  totalExtras: number;
  couponCode: ApplyCouponCodeType | null;
  transfersCashAmount: PaymentMethodCashAmountCars | null;
  pickupCountryCode: string;
  dropoffCountryCode: string;
  pickupIsAirport: boolean;
  dropoffIsAirport: boolean;
  setPickupIsAirport: (val: boolean) => void;
  setDropoffIsAirport: (val: boolean) => void;
  setPickupCountryCode: (val: string) => void;
  setDropoffCountryCode: (val: string) => void;
  setCouponCode: (value: ApplyCouponCodeType | null) => void;
  setTransfersCashAmount: (data: PaymentMethodCashAmountCars | null) => void;
  setTotalExtras: (total: number) => void;
  setExtrasAvailable: (data: ExtrasAvailableType[]) => void;
  setOnLoad: (val: boolean) => void;
  setDates: (date1: Date | Moment, date2: Date | Moment) => void;
  setDate: (key: "checkIn" | "checkOut", date: Date | Moment) => void;
  setPickup: (val: string) => void;
  setPickupTime: (val: string) => void;
  setPickupTime2: (val: string) => void;
  setPickupLat: (val: number) => void;
  setPickupLng: (val: number) => void;
  setDestination: (val: string) => void;
  setDestinationLat: (val: number) => void;
  setDestinationLng: (val: number) => void;
  setIsReturn: (val: boolean) => void;
  setAdults: (val: number) => void;
  setChildrens: (val: number) => void;
  setInfants: (val: number) => void;
  updatePax: (
    operator: "+" | "-",
    key: "adults" | "childrens" | "infants"
  ) => void;
  getTotalPax: () => number;
  setHideCalendar2: (val: boolean) => void;
  getCheckIn: (format?: string, autoLocale?: boolean) => string;
  getCheckOut: (format?: string, autoLocale?: boolean) => string;
  setSearchReq: (val: TransferSearchReqType | null) => void;
};

const TransfersContextDefaultValues: TransfersContextType = {
  dates: {
    checkIn: INIT_CHECKIN,
    checkOut: INIT_CHECKOUT,
  },
  pickup: "",
  pickupTime: "12:00",
  pickupTime2: "12:00",
  pickupLat: 0,
  pickupLng: 0,
  destination: "",
  destinationLat: 0,
  destinationLng: 0,
  isReturn: false,
  adults: 1,
  childrens: 0,
  infants: 0,
  hideCalendar2: true,
  onLoad: false,
  searchReq: null,
  extrasAvailable: [],
  totalExtras: 0,
  couponCode: null,
  transfersCashAmount: null,
  pickupCountryCode: "",
  dropoffCountryCode: "",
  pickupIsAirport: false,
  dropoffIsAirport: false,
  setPickupIsAirport: () => {},
  setDropoffIsAirport: () => {},
  setPickupCountryCode: () => {},
  setDropoffCountryCode: () => {},
  setCouponCode: () => {},
  setTransfersCashAmount: () => {},
  setTotalExtras: () => {},
  setExtrasAvailable: () => {},
  setOnLoad: () => {},
  setDate: () => {},
  setDates: () => {},
  setPickup: () => {},
  setPickupTime: () => {},
  setPickupTime2: () => {},
  setPickupLat: () => {},
  setPickupLng: () => {},
  setDestination: () => {},
  setDestinationLat: () => {},
  setDestinationLng: () => {},
  setIsReturn: () => {},
  setAdults: () => {},
  setChildrens: () => {},
  setInfants: () => {},
  updatePax: () => {},
  getTotalPax: () => 0,
  setHideCalendar2: () => {},
  getCheckIn: () => "",
  getCheckOut: () => "",
  setSearchReq: () => {},
};

const TransfersContext = createContext<TransfersContextType>(
  TransfersContextDefaultValues
);

export function useTransfers() {
  return useContext(TransfersContext);
}

type Props = {
  children: ReactNode;
};

export function TransfersProvider({ children }: Props) {
  const { isLoadedExternalConfig, isLoadedCheckLogin } = useLayoutStore(
    (state) => ({
      isLoadedExternalConfig: state.isLoadedExternalConfig,
      isLoadedCheckLogin: state.isLoadedCheckLogin,
    }),
    shallow
  );
  useEffect(() => {
    if (isLoadedExternalConfig && isLoadedCheckLogin) {
      setDates(getMinCheckinDays(), addDays(getMinCheckinDays(), 1));
    }
  }, [isLoadedExternalConfig, isLoadedCheckLogin]);
  const router = useRouter();
  const [dates, setDateInfo] = useState<TransfersDateType>(
    TransfersContextDefaultValues.dates
  );

  const [pickup, setPickupState] = useState(
    TransfersContextDefaultValues.pickup
  );

  const [pickupTime, setPickupTimeState] = useState(
    TransfersContextDefaultValues.pickupTime
  );

  const [pickupTime2, setPickupTime2State] = useState(
    TransfersContextDefaultValues.pickupTime2
  );

  const [pickupLat, setPickupLatState] = useState(
    TransfersContextDefaultValues.pickupLat
  );
  const [pickupLng, setPickupLngState] = useState(
    TransfersContextDefaultValues.pickupLng
  );
  const [destination, setDestinationState] = useState(
    TransfersContextDefaultValues.destination
  );
  const [destinationLat, setDestinationLatState] = useState(
    TransfersContextDefaultValues.destinationLat
  );
  const [destinationLng, setDestinationLngState] = useState(
    TransfersContextDefaultValues.destinationLng
  );
  const [adults, setAdultsState] = useState(
    TransfersContextDefaultValues.adults
  );
  const [isReturn, setIsReturnState] = useState(
    TransfersContextDefaultValues.isReturn
  );
  const [childrens, setChildrensState] = useState(
    TransfersContextDefaultValues.childrens
  );
  const [infants, setInfantsState] = useState(
    TransfersContextDefaultValues.infants
  );
  const [hideCalendar2, setHideCalendar2State] = useState(
    TransfersContextDefaultValues.hideCalendar2
  );

  const [onLoad, setOnLoadState] = useState(
    TransfersContextDefaultValues.onLoad
  );
  const [searchReq, setSearchReqState] = useState(
    TransfersContextDefaultValues.searchReq
  );

  const [extrasAvailable, setExtrasAvailableObj] = useState<
    ExtrasAvailableType[]
  >(TransfersContextDefaultValues.extrasAvailable);

  const [totalExtras, setTotalExtrasAmnt] = useState<number>(0);

  const [couponCode, setCouponCode] = useState<ApplyCouponCodeType | null>(
    TransfersContextDefaultValues.couponCode
  );
  const [transfersCashAmount, setTransfersCashAmount] =
    useState<PaymentMethodCashAmountCars | null>(
      TransfersContextDefaultValues.transfersCashAmount
    );

  const [pickupCountryCode, setPickupCountryCodeData] = useState(
    TransfersContextDefaultValues.pickupCountryCode
  );

  const [dropoffCountryCode, setDropoffCountryCodeData] = useState(
    TransfersContextDefaultValues.dropoffCountryCode
  );

  const [pickupIsAirport, setPickupIsAirportState] = useState(
    TransfersContextDefaultValues.pickupIsAirport
  );

  const [dropoffIsAirport, setDropoffIsAirportState] = useState(
    TransfersContextDefaultValues.dropoffIsAirport
  );
  const setDate = (key: "checkIn" | "checkOut", date: Date | Moment) => {
    setDateInfo({
      ...dates,
      [key]: date,
    });
  };

  const setDates = (date1: Date | Moment, date2: Date | Moment) => {
    setDateInfo({
      checkIn: date1,
      checkOut: date2,
    });
  };

  const setPickup = (val: string) => {
    setPickupState(val);
  };
  const setPickupTime = (val: string) => {
    setPickupTimeState(val);
  };
  const setPickupTime2 = (val: string) => {
    setPickupTime2State(val);
  };

  const setPickupLat = (val: number) => {
    setPickupLatState(val);
  };

  const setPickupLng = (val: number) => {
    setPickupLngState(val);
  };

  const setDestination = (val: string) => {
    setDestinationState(val);
  };

  const setDestinationLat = (val: number) => {
    setDestinationLatState(val);
  };

  const setDestinationLng = (val: number) => {
    setDestinationLngState(val);
  };

  const setIsReturn = (val: boolean) => {
    setIsReturnState(val);
    handlerHideCalendarCol(!val);
  };
  const setAdults = (val: number) => {
    setAdultsState(val);
  };
  const setChildrens = (val: number) => {
    setChildrensState(val);
  };
  const setInfants = (val: number) => {
    setInfantsState(val);
  };
  const getTotalPax = () => {
    return adults + childrens + infants;
  };

  const setHideCalendar2 = (val: boolean) => {
    setHideCalendar2State(val);
  };

  const setPickupIsAirport = (val: boolean) => {
    setPickupIsAirportState(val);
  };

  const setDropoffIsAirport = (val: boolean) => {
    setDropoffIsAirportState(val);
  };

  const handlerHideCalendarCol = (ban: boolean) => {
    if (ban) {
      setHideCalendar2(ban);
    } else {
      setTimeout(() => {
        setHideCalendar2(false);
      }, 300);
    }
  };
  const setSearchReq = (val: TransferSearchReqType | null) => {
    setSearchReqState(val);
  };
  const getCheckIn = (format = "YYYY-MM-DD", autoLocale = false) =>
    getDateToFormat(dates.checkIn, format, autoLocale);

  const getCheckOut = (format = "YYYY-MM-DD", autoLocale = false) =>
    getDateToFormat(dates.checkOut, format, autoLocale);

  const updatePax = (
    operator: "+" | "-",
    key: "adults" | "childrens" | "infants"
  ) => {
    let aux: any = {
      adults,
      childrens,
      infants,
    };
    let pax: any = {
      adults,
      childrens,
      infants,
    };

    if (operator === "+") {
      if (getTotalPax() >= 9 || (key == "infants" && pax.infants >= 1)) return;
      const val = aux[key] + 1;
      aux = { ...aux, [key]: val };
    } else {
      const val = aux[key] - 1;
      if ((key == "adults" && val < 1) || (key !== "adults" && val < 0)) return;
      aux = { ...aux, [key]: val };
    }
    setAdults(aux.adults);
    setChildrens(aux.childrens);
    setInfants(aux.infants);
  };

  const setOnLoad = (state: boolean) => {
    setOnLoadState(state);
  };

  const setExtrasAvailable = (data: ExtrasAvailableType[]) => {
    setExtrasAvailableObj(data);
  };
  const setTotalExtras = (amnt: number) => {
    setTotalExtrasAmnt(amnt);
  };

  const setPickupCountryCode = (val: string) => {
    setPickupCountryCodeData(val);
  };

  const setDropoffCountryCode = (val: string) => {
    setDropoffCountryCodeData(val);
  };

  // * the states is updated when the url changes
  useEffect(() => {
    const pagesAllow = ["/transfers/search-details", "/transfers/details"];
    if (pagesAllow.includes(router.pathname)) {
      const query = router.query;

      const params = getParamsFromQuery(query);
      console.log("params========", params, params.checkIn);
      setPickupCountryCodeData(params.pickupCountryCode);
      setDropoffCountryCodeData(params.dropoffCountryCode);
      setPickup(params.pickup);
      setPickupTime(params.pickupTime);
      setPickupTime2(params.pickupTime2);
      setPickupLat(params.pickupLat);
      setPickupLng(params.pickupLng);
      setDestination(params.destination);
      setDestinationLat(params.destinationLat);
      setDestinationLng(params.destinationLng);
      setIsReturn(params.isReturn);
      setAdults(params.adults);
      setChildrens(params.childrens);
      setInfants(params.infants);
      setDates(params.checkIn, params.checkOut);
      setPickupIsAirport(params.pickupIsAirport);
      setDropoffIsAirport(params.dropoffIsAirport);
      setDates(params.checkIn, params.checkOut);
      const checkinDate = getDateToFormat(params.checkIn, "YYYY-MM-DD", false);
      const checkoutDate = getDateToFormat(
        params.checkOut,
        "YYYY-MM-DD",
        false
      );
      const reqSearcParams = getRequestSearchFromStates(
        params.pickupLat,
        params.pickupLng,
        params.destinationLat,
        params.destinationLng,
        params.pickup,
        params.destination,
        params.isReturn,
        checkinDate,
        params.isReturn ? checkoutDate : checkinDate,
        getHourFormatHoppa(params.pickupTime),
        params.isReturn
          ? getHourFormatHoppa(params.pickupTime2)
          : getHourFormatHoppa(params.pickupTime),
        params.adults,
        params.childrens,
        params.infants,
        params.pickupCountryCode,
        params.dropoffCountryCode
      );
      setSearchReq(reqSearcParams);
    }
    // setOnLoad(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query]);

  const value = {
    dates,
    pickup,
    pickupTime,
    pickupTime2,
    pickupLat,
    pickupLng,
    destination,
    destinationLat,
    destinationLng,
    isReturn,
    adults,
    childrens,
    infants,
    hideCalendar2,
    onLoad,
    searchReq,
    extrasAvailable,
    totalExtras,
    couponCode,
    transfersCashAmount,
    pickupCountryCode,
    dropoffCountryCode,
    pickupIsAirport,
    dropoffIsAirport,
    setPickupIsAirport,
    setDropoffIsAirport,
    setPickupCountryCode,
    setDropoffCountryCode,
    setCouponCode,
    setTransfersCashAmount,
    setTotalExtras,
    setExtrasAvailable,
    setOnLoad,
    setDates,
    setDate,
    setPickup,
    setPickupTime,
    setPickupTime2,
    setPickupLat,
    setPickupLng,
    setDestination,
    setDestinationLat,
    setDestinationLng,
    setIsReturn,
    setAdults,
    setChildrens,
    setInfants,
    updatePax,
    getTotalPax,
    setHideCalendar2,
    getCheckIn,
    getCheckOut,
    setSearchReq,
  };

  TransfersContext.displayName = "TransfersContext";
  return (
    <TransfersContext.Provider value={value}>
      {children}
    </TransfersContext.Provider>
  );
}
