import { INIT_CHECKIN, INIT_CHECKOUT } from "@data/consts";
import { getParamsFromQuery } from "@helpers/helperHotel";
// import { addDays } from "date-fns";
import moment from "moment";
import { Moment } from "moment";
import { useRouter } from "next/router";
import { useHotelSearchDetailStore } from "store/hotelSearchDetailStore";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

type hotelContextType = {
  search: SearchType;
  searchInit: SearchType | null;
  setSearch: (search: SearchType) => void;
  bookInfo: BookInfoType;
  setBookInfo: (bookinInfo: BookInfoType) => void;
  travelers: SelectTravelerType[];
  travBookUniqueId: string | undefined;
  currentRoomsSelecctions: CurrentSelectionType[] | undefined;
  currentHotelMinPrice: number;
  hotelMoreFilters: number;
  couponCode: ApplyCouponCodeResponseType | null;
  hotelCashAmount: PaymentMethodCashAmountCars | null;
  setHotelCashAmount: (data: PaymentMethodCashAmountCars | null) => void;
  setCouponCode: (data: ApplyCouponCodeResponseType | null) => void;
  setCurrentHotelMinPrice: (val: number) => void;
  setHotelMoreFilters: (val: number) => void;
  setBookSelection: (currentRooms: CurrentSelectionType[]) => void;
  setTraveler: (travelers: SelectTravelerType) => void;
  setTravelers: (travelers: SelectTravelerType[]) => void;
  setTravBookUniqueId: (val: string) => void;
  setSearchDates: (date1: Date | Moment, date2: Date | Moment) => void;
  setDestinationId: (val: number) => void;
  setSearchText: (val: string) => void;
  setLatitude: (val: number) => void;
  setLongitude: (val: number) => void;
  setCustomSearch: (search: SearchType) => void;
  clearBookInfo: () => void;
  setSearchInfoInit: (search: SearchType | null) => void;
};
const searchInitData = {
  dates: {
    checkIn: INIT_CHECKIN,
    checkOut: INIT_CHECKOUT,
  },
  destination_id: null,
  search_type: null,
  search_text: "",
  dest_type: null,
  latitude: null,
  longitude: null,
  paxConfig: [{ room: 1, adult: 2, children: 0, child: [] }],
  datesFormated: undefined,
};
const hotelContextDefaultValues: hotelContextType = {
  search: searchInitData,
  searchInit: null,
  bookInfo: {
    roomIds: [],
    couponCode: "",
    usrType: 2,
    cardid: "",
    cashcardAmount: "",
    cashcard: "",
    tripId: "",
    pgAmt: "",
  },
  travBookUniqueId: undefined,
  travelers: [],
  currentHotelMinPrice: 0,
  hotelMoreFilters: 0,
  couponCode: null,
  setCouponCode: () => {},
  setCurrentHotelMinPrice: () => {},
  setHotelMoreFilters: () => {},
  setSearch: () => {},
  currentRoomsSelecctions: [],
  setBookSelection: () => {},
  setBookInfo: () => {},
  setTraveler: () => {},
  setTravelers: () => {},
  setTravBookUniqueId: () => {},
  setSearchDates: () => {},
  setDestinationId: () => {},
  setSearchText: () => {},
  setLatitude: () => {},
  setLongitude: () => {},
  setCustomSearch: () => {},
  hotelCashAmount: null,
  setHotelCashAmount: () => {},
  clearBookInfo: () => {},
  setSearchInfoInit: () => {},
};

const HotelContext = createContext<hotelContextType>(hotelContextDefaultValues);

export function useHotel() {
  return useContext(HotelContext);
}

type Props = {
  children: ReactNode;
};

export function HotelProvider({ children }: Props) {
  const router = useRouter();
  const {
    setExecuteFisrsTimeSearchByRoomOccupancy,
    setIsLoading,
    clearRecommendedCards,
    clearDataCards,
    clearDataHotels,
    setSearchHotelName,
  } = useHotelSearchDetailStore();
  const [search, setSearchInfo] = useState<SearchType>(
    hotelContextDefaultValues.search
  );
  const [searchInit, setSearchInfoInit] = useState<SearchType | null>(
    hotelContextDefaultValues.search
  );
  const [bookInfo, setBookingInfo] = useState<BookInfoType>(
    hotelContextDefaultValues.bookInfo
  );
  const [travelers, setTravelersInfo] = useState<SelectTravelerType[]>(
    hotelContextDefaultValues.travelers
  );
  const [travBookUniqueId, setTravBookUniqueIdInfo] = useState<
    string | undefined
  >(hotelContextDefaultValues.travBookUniqueId);

  const [currentRoomsSelecctions, setCurrentSelecctions] = useState<
    CurrentSelectionType[] | undefined
  >(hotelContextDefaultValues.currentRoomsSelecctions);

  const [currentHotelMinPrice, setCurrentHotelMinPriceData] = useState<number>(
    hotelContextDefaultValues.currentHotelMinPrice
  );

  const [hotelMoreFilters, setHotelMoreFiltersData] = useState<number>(
    hotelContextDefaultValues.hotelMoreFilters
  );

  const [couponCode, setCouponCodeResponse] =
    useState<ApplyCouponCodeResponseType | null>(null);

  const [hotelCashAmount, setHotelCashAmount] =
    useState<PaymentMethodCashAmountCars | null>(
      hotelContextDefaultValues.hotelCashAmount
    );

  const setSearch = (search: SearchType) => {
    setSearchInfo(search);
  };

  const setSearchDates = (date1: Date | Moment, date2: Date | Moment) => {
    setSearchInfo({
      ...search,
      dates: {
        checkIn: date1,
        checkOut: date2,
      },
    });
  };

  const setDestinationId = (destionation: number) => {
    setSearchInfo({
      ...search,
      destination_id: destionation,
    });
  };

  const setSearchText = (searchText: string) => {
    setSearchInfo({
      ...search,
      search_text: searchText,
    });
  };

  const setLatitude = (newLatitude: number) => {
    setSearchInfo({
      ...search,
      latitude: newLatitude,
    });
  };
  const setLongitude = (longitude: number) => {
    setSearchInfo({
      ...search,
      longitude: longitude,
    });
  };

  const setCustomSearch = (search: SearchType) => {
    setSearchInfo(search);
  };

  const setBookInfo = (bookinInfo: BookInfoType) => {
    setBookingInfo(bookinInfo);
  };

  const setTraveler = (travelerInfo: SelectTravelerType) => {
    setTravelersInfo([...travelers, travelerInfo]);
  };

  const setTravelers = (travelerInfo: SelectTravelerType[]) => {
    setTravelersInfo([...travelerInfo]);
  };

  const setTravBookUniqueId = (val: string) => {
    setTravBookUniqueIdInfo(val);
  };

  const setBookSelection = (currentRooms: CurrentSelectionType[]) => {
    setCurrentSelecctions(currentRooms);
    return currentRooms;
  };

  const setCurrentHotelMinPrice = (val: number) => {
    setCurrentHotelMinPriceData(val);
  };

  const setHotelMoreFilters = (val: number) => {
    setHotelMoreFiltersData(val);
  };

  const setCouponCode = (data: ApplyCouponCodeResponseType | null) => {
    setCouponCodeResponse(data);
  };

  const clearBookInfo = () => {
    setBookInfo({
      roomIds: [],
      couponCode: "",
      usrType: 2,
      cardid: "",
      cashcardAmount: "",
      cashcard: "",
      tripId: "",
      pgAmt: "",
    });
  };

  useEffect(() => {
    const searchDetailPage = ["/search-detail"];
    if (searchDetailPage.includes(router.pathname)) {
      console.log("useEffect search details");

      const query = router.query;
      const params = getParamsFromQuery(query);
      const day1 = moment(params.startDate, "YYYY-MM-DD").toDate();
      const day2 = moment(params.endDate, "YYYY-MM-DD");
      try {
        setIsLoading(true);
        clearRecommendedCards();
        clearDataCards();
        clearDataHotels();
        setSearchHotelName("");
        setExecuteFisrsTimeSearchByRoomOccupancy(true);
      } catch (error) {
        console.log(`hotelContext()_ error`, { error: error });
      }
      setSearchInfo({
        ...params,
        search_text: params.location,
        dates: {
          checkIn: day1,
          checkOut: day2,
        },
      });
      setSearchInfoInit({
        ...params,
        search_text: params.location,
        dates: {
          checkIn: params.startDate,
          checkOut: params.startDate,
        },
        ts: Date.now(),
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query]);

  const value = {
    search,
    bookInfo,
    travelers,
    travBookUniqueId,
    currentRoomsSelecctions,
    currentHotelMinPrice,
    hotelMoreFilters,
    couponCode,
    hotelCashAmount,
    setHotelCashAmount,
    searchInit,
    setCouponCode,
    setCurrentHotelMinPrice,
    setHotelMoreFilters,
    setSearch,
    setBookInfo,
    setTraveler,
    setTravelers,
    setTravBookUniqueId,
    setBookSelection,
    setSearchDates,
    setDestinationId,
    setSearchText,
    setLatitude,
    setLongitude,
    setCustomSearch,
    clearBookInfo,
    setSearchInfoInit,
  };
  HotelContext.displayName = "HotelContext";
  return (
    <>
      <HotelContext.Provider value={value}>{children}</HotelContext.Provider>
    </>
  );
}
